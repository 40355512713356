import LoginButtons from "../components/admin/sidebar/items/LoginButtons";
import React, {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";

export default function LoginPage(props) {
    const {organizationStore} = useContext(StoreContext);

    return <div>
        <div id="sb-main-content" className="sb-login-container sb-content-width" style={{maxWidth: "600px", minHeight: 0}}>
            <div className="sb-login-block">
                <div className="sb-login-school-header">
                    <h2 className="sb-organization-color-block-font" style={{marginTop: "0.5em", fontWeight: "bold"}}>
                        {organizationStore.organization.title}
                    </h2>
                    <h3 style={{marginTop: "0.5em"}}>Sign In</h3>
                </div>
        <LoginButtons />
            </div>
        </div>
    </div>
}
